import { FormControl } from '@mui/material';
import { t } from 'i18next';
import React, { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { InputStyled } from '../../../../../../theme/style';
import { adaptTranslationByChartTypeTranslationByChartType, CHART_INDICATOR_NAME } from '../../../constants';
import { CreateIndicatorFetchProps } from '../../../dashboardModalInterface';
import { FormLabelStyled } from '../../styled';

interface CustomTitleInputProps {
    displayType: CHART_INDICATOR_NAME
    setCreateIndicator: Dispatch<SetStateAction<CreateIndicatorFetchProps>>
}

const CustomTitleInput: FC<CustomTitleInputProps> = ({ displayType, setCreateIndicator }) => {
    const handleChangeTitle = (event: ChangeEvent<HTMLInputElement>) => {
        setCreateIndicator((prev) => ({ ...prev, customItemTitle: event.target.value }));
    };

    return (
        <FormControl>
            <FormLabelStyled>
                {adaptTranslationByChartTypeTranslationByChartType(displayType).title}
                <span>{t('dashboard:addIndicatorModal.commonAddIndicator.changeTitleKeyFigureSpan')}</span>
            </FormLabelStyled>
            <InputStyled
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeTitle(e)}
                placeholder={t('dashboard:addIndicatorModal.commonAddIndicator.changeTitleKeyFigurePlaceholder')}
            />
        </FormControl>
    );
};

export default CustomTitleInput;
