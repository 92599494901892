import { AuthorizedContent } from '@frontegg/react';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { colors, fontSize, fontWeight, TypographyStyledGlobal } from '../../../theme';
import AlertType from '../../../utils/enum/AlertType';
import RolesType from '../../../utils/enum/RolesType';
import useFetcher from '../../../utils/hooks/useFetcher';
import Loader from '../../Loader/loader';
import Message from '../../Message/Message';
import DeleteIndicatorModal from '../../Modals/ConfirmationModal/DeleteIndicator';
import { CHART_INDICATOR_NAME } from '../../Modals/DashboardModal/constants';
import EditIndicatorModal from '../../Modals/EditModal/EditIndicator';
import ActionsCard from '../ActionsCard/actionsCard';
import { ClauseFilterProps, DashboardParams, DateRangeScopeProps, Indicator, ScopeProps } from '../dashboardInterfaces';
import { IndicatorCardStyled, IndicatorCardHeader, IndicatorCardHeaderBase, ButtonCardStyled } from '../styled';

interface IndicatorCardProps {
    indicator: Indicator
    selectIndicatorCard: (displayType: Indicator['displayType'], data: Indicator['data']) => any,
    selectedClauseFilter: Array<ClauseFilterProps>
    selectedScope: Array<ScopeProps>
    selectedDateRange: DateRangeScopeProps | null
}

const IndicatorCard: FC<IndicatorCardProps> = ({ indicator, selectIndicatorCard, selectedClauseFilter = [], selectedScope = [], selectedDateRange }) => {
    const { t } = useTranslation('dashboard');
    const { surveyId, dashboardId } = useParams<DashboardParams>();

    const clauseFilters = JSON.stringify(selectedClauseFilter.map((clauseFilter: ClauseFilterProps) => ({ [clauseFilter.filterId]: clauseFilter.modalityId.map((modalityId: string) => parseInt(modalityId, 10)) })));
    const selectedScopes = JSON.stringify(selectedScope.map((scope: ScopeProps) => ({ [scope.scopeId]: scope.modalityId.map((modalityId: string) => parseInt(modalityId, 10)) })));
    const selectedDateRanges = JSON.stringify({ startDate: selectedDateRange?.startDate.toLocaleDateString('fr-CA'), endDate: selectedDateRange?.endDate.toLocaleDateString('fr-CA'), key: selectedDateRange?.key });
    const filterScopeArray = [selectedClauseFilter.length > 0 ? `filters=${clauseFilters}` : '', selectedScope.length > 0 ? `scopes=${selectedScopes}` : '', selectedDateRange ? `dateRanges=${selectedDateRanges}` : ''].filter((fs: string) => fs !== '');
    const filterScopeLink = filterScopeArray.length > 0 ? `/?${filterScopeArray.join('&')}` : '';
    const { data } = useSWR(`${process.env.REACT_APP_API_URL}/survey/${surveyId}/dashboard/${dashboardId}/compute/${indicator.id}/${indicator.displayType}${filterScopeLink}`, useFetcher());
    const content = selectIndicatorCard(indicator.displayType, data);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const handleDeleteCard = () => {
        setOpenDeleteModal(true);
    };
    const handleEditTitleCard = () => {
        setOpenEditModal(true);
    };

    return (
        <IndicatorCardStyled>
            <IndicatorCardHeader>
                <TypographyStyledGlobal size={fontSize.m} weight={fontWeight.semiBold}>
                    {indicator.customItemTitle || indicator.question.label}
                    <AuthorizedContent requiredRoles={[RolesType.ADMIN, RolesType.ADMIN_MOAI]}>
                        <ButtonCardStyled onClick={handleEditTitleCard} variant='text'>({t('edit')})</ButtonCardStyled>
                    </AuthorizedContent>
                </TypographyStyledGlobal>
                <ActionsCard handleDeleteCard={handleDeleteCard} />
            </IndicatorCardHeader>
            <IndicatorCardHeaderBase>
                <TypographyStyledGlobal color={colors.grey400} fontStyle='italic' size={fontSize.sm} weight={fontWeight.semiRegular}>
                    { indicator.displayType !== CHART_INDICATOR_NAME.VERBATIM ? t('base', { count: data?.base }) : '' }
                </TypographyStyledGlobal>
            </IndicatorCardHeaderBase>
            {data?.error && <Message content={data.error} messageType={AlertType.WARNING} />}
            {content ?? <Loader size={50} />}
            <DeleteIndicatorModal indicator={indicator} openModal={openDeleteModal} setOpenModal={setOpenDeleteModal} />
            <EditIndicatorModal indicator={indicator} openModal={openEditModal} setOpenModal={setOpenEditModal} />
        </IndicatorCardStyled>
    );
};

export default IndicatorCard;
