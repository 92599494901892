import CloseIcon from '@mui/icons-material/Close';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import styled from 'styled-components';
import { colors } from '../../../theme';

export const DragButtonStyled = styled(DragIndicatorIcon)`
    && {
        color: ${colors.grey500};
    }
`;
export const CloseButtonStyled = styled(CloseIcon)`
    && {
        color: ${colors.grey500};
        margin-left: 10px;
        padding:0;
        cursor: pointer;
        &:hover {
            transition: all 0.3s ease-in-out;
            color: ${colors.grey300};
        }
    }
`;

export const ActionsCardStyled = styled.div`
    display: flex;
    @media print{
        display: none;
   }
`;
