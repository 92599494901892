import Fade from '@mui/material/Fade';
import React, { FC } from 'react';
import BoxModalStyled from '../styled';
import CreationIndicator from './createIndicator';
import { DashboardModalProps } from './dashboardModalInterface';
import { CloseIconModalStyled, ModalStyled } from './styled';

const DashboardModal: FC<DashboardModalProps> = ({ openModal, setOpenModal }) => {
    const handleClose = () => {
        setOpenModal(false);
    };

    return (
        <ModalStyled
            aria-describedby="modal-modal-description"
            aria-labelledby="modal-modal-title"
            onClose={handleClose}
            open={openModal}
        >
            <Fade in={openModal}>
                <BoxModalStyled>
                    <CloseIconModalStyled onClick={handleClose} />
                    <CreationIndicator setOpenModal={setOpenModal} />
                </BoxModalStyled>
            </Fade>
        </ModalStyled>
    );
};

export default DashboardModal;
