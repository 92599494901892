import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import { Avatar, Box, ListItem, ListItemText } from '@mui/material';
import styled from 'styled-components';
import { colors, fontFamilyPrimary, fontWeight } from '../../theme';
import { ActionButtonStyled } from '../Modals/DashboardModal/DashboardModalContent/styled';
import { TitleStyled } from '../Title/styled';

export const SurveyCardStyled = styled(Box)`
    && {
        background-color: ${colors.grey000};
        padding: 24px;
        flex-direction: column;
        border-radius: 8px;
        color: ${colors.grey200};
        max-width: 450px;
        box-shadow: 0px 10px 60px rgba(33, 22, 69, 0.04);
        width: 33.33%
    }
`;

export const SurveyTitleStyled = styled(TitleStyled)`
    && {
        margin-bottom: 24px;
    }
`;

export const ShareSurveyTitleStyled = styled(TitleStyled)`
    && {
        margin-top: 29px;
        margin-bottom: 29px;
        font-weight: 600;
        font-size: 20px;
    }
`;

export const SurveyCardShareStyled = styled(Box)`
    && {
        display: flex;
        flex-direction: column;
        max-width: 450px;
        width: 33.33%;
        background-color: ${colors.grey000};
        justify-content: space-between;
        padding: 24px;
        border-radius: 8px;
        box-shadow: 0px 10px 60px rgba(33, 22, 69, 0.04);
    }
`;

export const ShareSurveyCardStyled = styled(Box)`
    && {
        gap: 8px;
        display: flex;
        flex-direction: row;
        color: ${colors.grey200};
    }
`;

export const ContainerSurveyStyled = styled.div`
    display: flex;
    gap: 24px;
    flex-wrap: no-wrap;
    align-items: flex-start;
`;

export const SurveyCardHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

`;

export const ListIconStyled = styled(Avatar)`
    && {
        pointer-events: none;
        border-radius: 4px;
        background-color: ${colors.grey700};
        color: ${colors.greyDisabled};
        margin-right: 8px;
        width: 32px;
        height: 32px;
        box-sizing: border-box;
        svg {
            width: 16px;
            padding: 8px;
        }
        ${({ hasHover } : { hasHover: boolean }) => hasHover
            && `
                pointer-events: auto;
                background-color: ${colors.default};
                color: white;
                cursor: pointer;
                &:hover {
                    background: ${colors.defaultDarker};
                    color:white;
                }`
}
    }
`;

export const ListItemTextStyled = styled(ListItemText)`
    && {
        span {
            font-family: ${fontFamilyPrimary};
            color: ${colors.grey200};
            font-weight: ${fontWeight.semiRegular};
            line-height: 20px;
        }
    }
`;

export const ListItemStyled = styled(ListItem)`
    && {
        padding-bottom: 8px;
        padding-left: 0;
        padding-right: 0;
    }

`;

export const AddDiffusionListContainerStyled = styled.div`
    border: 1px dashed ${colors.default};
    border-radius: 8px;
    padding: 32px;
    box-sizing: border-box;
    margin: 60px auto;
    width: 60%;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    max-width: 610px;
`;

export const ErrorOutlinedIconStyled = styled(ErrorOutlinedIcon)`
    && {
        color: ${colors.grey500};
        font-size: 32px;
    }
`;

export const ActionSurveyButtonStyled = styled(ActionButtonStyled)`
    && {
        box-shadow: ${colors.defaultShadow};
    }
`;
